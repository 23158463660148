@import '../../style/App.scss';

#about {
  padding: 60px 0;
}

@media screen and (max-width: 990px) {
  #about {
    text-align: center;
  }
}