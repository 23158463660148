@import '../../style/App.scss';

.music {
  background-color: $light-color;
  padding: 60px 0 20px;
}

.swiper-slide iframe {
  border: none;
}

@media screen and (max-width: 990px) {
  #music {
    text-align: center;
  }
}
